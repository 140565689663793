.paralellogram {
  width: 100%;
  height: 1%;
  transform: skew(20deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  background-color: #fff;
}

.paralellogram a {
  transform: skew(-20deg);
  transition: all 0.5s ease-in-out;
}

.paralellogram a:hover {
  transform: skew(-20deg) scale(1.09);
  transition: all 0.2s ease-in-out;
}


.footer {
  position: fixed !important;
  bottom: 0;
  height: 8%;
  margin-top: 10%;
}

.content {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 500px;
}

.typewriter {
  font-size: 36px;
}

.projects {
  margin-top: 2.5%;
  margin-bottom: 10%;
}

svg:hover {
  transform: scale(1.2);
}

.card:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 992px) {

  h1 {
      align-items: center;
      text-align: center;
      
  }

}